import React, { useEffect, useState } from 'react'
import './style.css'
import Chart from '../../Chart/Chart';


function ChartData({heading, optionDetail}) {
    const initialOptions = {
        height: 100,
        width: 1100,
        
        animationEnabled: true,
        // animationDuration: 2200,
        // exportEnabled: true,
        theme: "light2", // "light1", "dark1", "dark2"
        // title:{
        //     text: "Bounce Rate by Week of Year"
        // },
        // axisY: {
        //     title: "Bounce Rate",
        //     suffix: "%",
        // },
        axisY:{
            lineThickness: 0,
            gridThickness: 0,
            tickLength: 0,
            labelFormatter: function(e) {
                return "";
            }
        },
        axisX: {
            // title: "Week of Year",
            prefix: "Wave",
            interval: 1
        },
        
        data: [{
            type: "line",
            indexLabel: "{y}%", // Display y-axis values as percentages
            indexLabelFontColor: "black", 
            indexLabelFontSize: 13, // Set the font size of the index labels
            // indexLabelPlacement: "inside",

            // indexLabelPlacement: "outside",
            // indexLabelPlacement: "inside", // Place the index labels inside the columns
            // indexLabelFontColor: "white", // Set the font color of the index labels to white
            // indexLabelBackgroundColor: "blue",
            
            toolTipContent: "{y}%",

            dataPoints: [
                { x: 1, y: 0 },
                { x: 2, y: 0 },
                { x: 3, y: 0 },
                { x: 4, y: 0 },
                { x: 5, y: 0 },
                { x: 6, y: 0 },
                { x: 7, y: 0 },
                { x: 8, y: 0 },
                { x: 9, y: 0 },
                { x: 10, y: 0 },
                { x: 11, y: 0 },
                { x: 12, y: 0 },
                { x: 13, y: 0 },
                { x: 14, y: 0 },
            ]
        }]
    }

    const [options, setOptions] = useState({...initialOptions})

    useEffect(() => {
        if(optionDetail){
        updateOptionDetail();
        }
    },[optionDetail]);

    const updateOptionDetail = () => {
        let dataPoints = [];
        console.log("optionDetail", optionDetail);
        dataPoints = optionDetail.map((waves, index) => {
         return {label: waves.label, y: waves.value}
        // return {x: index, y: waves.value}
        })

        console.log("dataPoints", dataPoints);
        if(dataPoints.length){
            let tempOptions = {...options} 
            tempOptions.data[0].dataPoints = dataPoints;
            setOptions(tempOptions);

            // setOptions((pre) => ({
            //     ...pre,
            //     data: [{
            //       ...pre.data,
            //       dataPoints: dataPoints,
            //    }]
            // }))
        }
    }

    useEffect(() => {
        console.log("options", options);
    }, [options])
   

  return (
    <div id='ChartData' className='my-4'>
    
    <div className="white-cntainer">
    <div className="d-flex align-items-center">
        <div className="heading-box">
            <h5>{heading}</h5>
        </div>
        <div className="arrao-box px-1">
            <img src={require('../../../assets/Polygon 1.png')} width="15" alt="" />
        </div>
        <div className="graph-box px-1">

        <Chart options={options} />

        </div>
    </div>
    </div>

    </div>
  )
}

export default ChartData
