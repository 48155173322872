import React, { memo } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { QUESTIONNAIRE_RESULT } from "../../constants";
import "./survey.css";

//  Survey.StylesManager.applyTheme("modern");
var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "#061b7f";
defaultThemeColors["$main-hover-color"] = "#410153";
Survey.StylesManager.applyTheme();

// var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];

// defaultThemeColors["$main-color"] = "#061b7f";
// defaultThemeColors["$border-color"] = "#061b7f";
// defaultThemeColors["$main-hover-color"] = "#061b7f";
// defaultThemeColors["$text-color"] = "#fffff";
// defaultThemeColors["$header-color"] = "#061b7f";

// defaultThemeColors["$header-background-color"] = "#dedede";
// defaultThemeColors["$body-container-background-color"] = "#f5f5f5";

// Survey.StylesManager.applyTheme(defaultThemeColors);
const SurveyCreatorComponent = ({
  questionnaireJson,
  handleComplete,
  answers,
  isEdit,
  handleStart,
  handleSetQuestion,
  onCompletingHandle,
  handleStarted,
  webClientSetting,
  closeQuestionnaire,
}) => {
  // useEffect(() => {
  //   if (closeQuestionnaire) {
  //     survey.doComplete();
  //     survey.onComplete.add((sender, res) => {});
  //   }
  // }, [closeQuestionnaire]);

  // useEffect(() => {
  //   setStartCountDown(true);
  // }, []);

  const survey = new Survey.Model(questionnaireJson);
  survey.onValueChanged.add(function (res, op) {
    let data = {
      answerJSON: res.data,
    };
    let stringify = JSON.stringify(data);
    localStorage.setItem(QUESTIONNAIRE_RESULT, stringify);
  });

  survey.data = answers;
  survey.mode = isEdit ? "" : "display";

  if (webClientSetting?.surveyExpireMessage) {
    survey.completedHtml = "-";
  }

  return (
    <div className="w-100 p-2">
      <Survey.Survey
        onAfterRenderQuestion={handleStart}
        onCurrentPageChanged={handleSetQuestion}
        onStarted={handleStarted}
        model={survey}
        onCompleting={onCompletingHandle}
        onComplete={handleComplete}
      />
    </div>
  );
};
export default memo(SurveyCreatorComponent);
