import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./style.css";
import ChartSummary from "../../components/ProductDashboard/ChartSummary/ChartSummary";
import ChartData from "../../components/ProductDashboard/ChartData/ChartData";
import ManageVideo from "../../components/ManageVideo/ManageVideo";
import { fetchFilteredDocumentByLevels, getFiltersSetting } from "../../apis";
import { useParams } from "react-router-dom";

function ProjectDashboard() {
  const { id: projectId } = useParams();

  const [levelsDropdown, setLevelsDropdown] = useState();
  const [
    selectedGeneralDetailFilterValues,
    setSelectedGeneralDetailFilterValues,
  ] = useState({});
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (projectId) {
      const getDashboardLevelFilter = async (projectId) => {
        try {
          debugger;
          // handleLoading("fetchLoading", true);
          const { status, data } = await getFiltersSetting(projectId);
          if (status === 200 && data?.data) {
            console.log("data?.data", data?.data);
            setLevelsDropdown(data?.data);
          }
          // handleLoading("fetchLoading", false);
        } catch (error) {
          // handleLoading("fetchLoading", false);
        }
      };
      getDashboardLevelFilter(projectId);
    }
  }, [projectId]);

  const handleGeneralDetailFilterDropdownChange = (event, dropdownName) => {
    debugger;
    const { value } = event.target;
    const res = removeLevelsAfterSelected(
      dropdownName,
      selectedGeneralDetailFilterValues
    );
    setSelectedGeneralDetailFilterValues((prevValues) => ({
      ...res,
      [dropdownName]: value,
    }));
  };

  // Function to remove key-value pairs after the selected level
  const removeLevelsAfterSelected = (selectedLevel, dropdownoptions) => {
    if (dropdownoptions[selectedLevel]) {
      const selectedLevelIndex =
        Object.keys(dropdownoptions).indexOf(selectedLevel);
      const keysToRemove = Object.keys(dropdownoptions).slice(
        selectedLevelIndex + 1
      );
      keysToRemove.forEach((key) => {
        delete dropdownoptions[key];
      });
    }
    return dropdownoptions;
  };

  useEffect(() => {
    console.log(
      "selectedGeneralDetailFilterValues",
      selectedGeneralDetailFilterValues
    );
    if (levelsDropdown?.dropDownFilters) {
      let dropDownFiltersLastLevel = Object.keys(
        levelsDropdown?.dropDownFilters
      )[Object.keys(levelsDropdown?.dropDownFilters).length - 1];
      let selectedGeneralDetailFilterValuesLastLevel = Object.keys(
        selectedGeneralDetailFilterValues
      )[Object.keys(selectedGeneralDetailFilterValues).length - 1];
      console.log(
        "first",
        dropDownFiltersLastLevel,
        "second",
        selectedGeneralDetailFilterValuesLastLevel
      );
      if (
        dropDownFiltersLastLevel === selectedGeneralDetailFilterValuesLastLevel
      ) {
        console.log("call api");

        getFilteredDocumentByLevels();
      }
    }
  }, [selectedGeneralDetailFilterValues]);

  const getFilteredDocumentByLevels = async () => {
    let payload = {
      projectId,
      ...selectedGeneralDetailFilterValues,
    };
    const { status, data } = await fetchFilteredDocumentByLevels(payload);
    console.log(
      "fetchFilteredDocumentByLevels",
      status,
      data,
      data[0]?.waveDataTable
    );
    if (status === 200 && data.length) {
      setChartData(data[0]?.waveDataTable);
    }else{
      setChartData([])
    }
  };

  function beautify(str) {
    str = str.replaceAll("_", " ");
    str = str.replaceAll("&", " & ");
    return str;
  }

  console.log("z",  chartData[0]?.waves[chartData[0]?.waves?.length - 1]?.date);

  return (
    <div>
      {/* //--------layout = redirect user to /projects if guesUser is undefined, false, null */}
      {/* //------AnonymousSocialQuestionnaire = gives modal if api response is undefined from webClientApi */}
      {/* <Header /> */}

      <div id="ProjectDashboard">
        <div className="d-flex flex-row justify-content-between">
          <div className="">
            <img src={levelsDropdown?.logo || require("../../assets/logo.png")} width="100" alt="" />
          </div>
          <div className="heading-div text-center">
            <h5 className="text-capitalize heading-1">
              pak suzuki dealer assessment report ( { chartData[0]?.waves[chartData[0]?.waves?.length - 1]?.date || " - "} ) {" "}
            </h5>
            <h5 className="heading-2">NEW CAR SHOWROOM</h5>
          </div>

          <div className="">
            {/* <img src={require('../../assets/logo.png')} width="100" alt="" /> */}
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between mt-3">
          {levelsDropdown?.dropDownFilters
            ? Object.keys(levelsDropdown.dropDownFilters)?.map(
                (dropdown, index) => {
                  if (index === 0) {
                    return (
                      <div key={index} className="mb-1">
                        <label htmlFor="">
                          {levelsDropdown?.levelsJSON?.[index]["name"]}
                        </label>
                        <select
                          style={{ height: "32px", width: "360px" }}
                          className="form-select form-control form-select-sm fontSize_12"
                          onChange={(e) =>
                            handleGeneralDetailFilterDropdownChange(e, dropdown)
                          }
                          value={
                            selectedGeneralDetailFilterValues[dropdown] || ""
                          }
                          aria-label="Default select example"
                        >
                          <option selected>select option</option>
                          {levelsDropdown?.dropDownFilters[dropdown]?.map(
                            (option) => {
                              return (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    );
                  } else {
                    const previousDropdownSelectedValue =
                      selectedGeneralDetailFilterValues[
                        Object.keys(levelsDropdown.dropDownFilters)[index - 1]
                      ];
                    return (
                      <div key={index} className="mb-1">
                        <label htmlFor="">
                          {levelsDropdown?.levelsJSON?.[index]["name"]}
                        </label>
                        <select
                          value={
                            selectedGeneralDetailFilterValues[dropdown] || ""
                          }
                          style={{ height: "32px", width: "360px" }}
                          onChange={(e) =>
                            handleGeneralDetailFilterDropdownChange(e, dropdown)
                          }
                          className="form-select form-control form-select-sm fontSize_12"
                          aria-label="Default select example"
                        >
                          <option selected>select option</option>
                          {/* Render options based on the selected value of the previous dropdown */}
                          {levelsDropdown?.dropDownFilters[dropdown][
                            previousDropdownSelectedValue
                          ]?.map((option) => {
                            return (
                              <option key={option?.id} value={option?.id}>
                                {option?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  }
                }
              )
            : ""}
        </div>

        {levelsDropdown && chartData.length === 0 && <ChartSummary />}

        {chartData.length > 0 && <ChartSummary chartData={chartData} />}

        {/* <ManageVideo /> */}

        {levelsDropdown && chartData.length === 0 && (
          <>
            <ChartData heading={"Overall Score"} />

             <ChartData heading={"Initail"} />


            {/* <ChartData heading={"Interior, Exterior & Ambiance"} /> */}

            {/* <ChartData heading={"Reception"} />

            <ChartData heading={"Skills Evaluation"} /> */}
          </>
        )}

        {chartData.length > 0 && (
          <>
            {chartData.reverse().map((cur) => (
              <>
                <ChartData
                  heading={beautify(cur.label)}
                  optionDetail={cur.waves}
                />
              </>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default ProjectDashboard;
