import React from "react";
import logo from "../../assets/biCruxcoluredLogo.svg";
import { BICRUX_URL } from "../../constants";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "rgb(229, 229, 229)",
        position: "fixed",
        width: "100%",
        bottom: 0,
      }}
    >
      <div
        className="px-4 py-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 5,
        }}
      >
        <div>
          <span style={{ color: "grey", fontWeight: "bold", fontSize: 12 }}>
            {/* Version : 1.2.2 */}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ color: "grey", fontSize: "11px" }}>
            Copyright © BICRUX 2022
          </span>

          <a href={BICRUX_URL} target="_blank">
            <img
              className="px-1"
              alt="footer-logo"
              src={logo}
              style={{
                height: "28px",
                width: "77px",
                position: "relative",
                top: "-2px",
              }}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
